import React,{useState,useEffect} from 'react';
import Navbar from "../Navbar";
import axios from 'axios';
import {Tab,Tabs} from 'react-bootstrap';
import {Link} from "react-router-dom";
import "./Chapters.css";

const Course_List =(props)=> {
  
   
          return (
            <>
         
               <div className="col-lg-4 mt-3">
                <div className="card">
                <Link className="link_style" to={`/courses/${props.id}`}>
                <div className="card-body">
                {props.name}  
                </div>
                </Link>
                </div>
                </div>
                   
            </>
        )
   
}
export default Course_List