import React,{useState,useEffect} from 'react';
import chapterFile from './chapterFile.png';
import { Link } from 'react-router-dom';
import Cards from "./Assignment_Card";
import axios from "axios";
import {Spin} from "antd"

const Test_Series=(props) => {
	
	const [assignment,setAssignment] = useState([]);
	const [datas,setData] = useState([]);
	const [loaded,setLoaded]=useState(false);
	useEffect(()=>
    {
        const token = localStorage.Ltoken
			const headers = {
				"Content-Type": "application/json",
				"AUTHORIZATION": `Token ${token}`
				
			  }
			  axios.get( `${process.env.REACT_APP_API_URL}/learning/api/test-series/`, {headers:headers} )
			  .then( response => {
				  setAssignment(response.data.data)
				  setLoaded(true)
			  } )
			  .catch(error => {
				  console.log(error);
				  setLoaded(true)

			  });
	},[])
	

	useEffect(()=>
	{
		setData(assignment.map((data)=>
		<>
			<Cards name={data.name} is_checked={data.is_checked} is_finished={data.is_finished} id={data.id} ques={data.no_of_questions}/>
			{/* <div className="col-md-2 mt-3">
			<div className="card">
  			<div className="card-body">{test.name}</div>
  			</div>
			</div> */}
		</>
		))
	},[assignment])
    return (   
        <React.Fragment>
		
            <div className="row mt-5">
			{loaded&&
			<>
			{datas}
			</>
			
			}

		{datas.length<=0?(<div className='container text-center'>No assignments available..</div>):(null)}
			{!loaded&&
			<div className="text-center container"><Spin/></div>
			}
		{/* <Cards name="Assignment" myadd="test1"/>
		<Cards name="Assignment1"  myadd="test2"/>
		<Cards name="Assignment2"  myadd="test3"/>
		<Cards name="Assignment3"  myadd="test4"/>
		<Cards name="Assignment4"  myadd="test5"/>
		<Cards name="Assignment5"  myadd="test6"/>
		<Cards name="Assignment6"  myadd="test7"/>
		<Cards name="Assignment7"  myadd="test8"/>
		<Cards name="Assignment8"  myadd="test9"/> */}
        </div>
        </React.Fragment>
    )
}
export default Test_Series