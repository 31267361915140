import React,{useState,useEffect} from 'react';
import Navbar from '../Navbar';
import chapterFile from './chapterFile.png';
import axios from 'axios';
import SectionDetail from '../SectionDetail';
import Headline from '../heading';
import {Tab,Tabs, NavItem} from 'react-bootstrap';
import {Link,Redirect} from "react-router-dom";
import Question from "./After_Question";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;





const After_Submit =(props)=> {
   
    const [content, setContent] = useState({
        question:"",
        nums:"",
        title:''
        ,
    });
    const [loaded,setLoading]=useState(false);
    const [useranswer,setUserAnswer]=useState("N/A");

    useEffect(()=>{
        const token = localStorage.Ltoken
        const keys = localStorage.assignment

			const headers = {
				"Content-Type": "application/json",
				"AUTHORIZATION": `Token ${token}`
				
        }
        axios.get( `${process.env.REACT_APP_API_URL}/learning/api/upload-pdf-answers/?test_series=${props.match.params.id}`, {headers:headers} )
        .then( response => {
                const x=response.data.data.map(d=>d.answersheet)
                setUserAnswer(x[0])
  
			  axios.get( `${process.env.REACT_APP_API_URL}/learning/api/test-series-view/?test_series=${props.match.params.id}`, {headers:headers} )
			  .then( response => {
                    setContent({question:response.data.data.question_paper,nums:response.data.data.no_of_questions,title:response.data.data.name,answer:response.data.data.test_answer_key_pdf})
                  setLoading(true);

                 
			  } )
			  .catch(error => {
				  console.log(error);
        });
        
                   
      } )
    },[])

  


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    // const refContainer = useRef(null);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }
  
    function changePage(offset) {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }
  
    function setPage(num) {
      // setPageNumber(num);
      if (
        parseInt(num.target.value) >= 1 &&
        parseInt(num.target.value) <= numPages
      )
        setPageNumber(parseInt(num.target.value));
    }
  
    function previousPage() {
      changePage(-1);
    }
  
    function nextPage() {
      changePage(1);
    }
  
    function scaleup() {
      setScale((prev) => prev + 0.5);
    }
  
    function scaledown() {
      setScale((prev) => prev - 0.5);
    }
  
   

        return (
            <div className="mb-5">
               <Navbar />
                <main className="container-fluid mt-5">
                <h1 className="text-center" style={{fontSize:"22px"}}>Assignment - {content.title}</h1>
                <ul className="list-group list-group-horizontal">
                {/* <Link to={`/assignment`}><li  key="2" className="list-group-item"><i class="fas fa-angle-left mr-2"></i>  Back</li></Link>

                <Link to={`/assignments/${props.match.params.id}/submission`}><li className="list-group-item active" >My Submission</li></Link>
                */}

                <Link to={`/assignment`}><li  key="2" className="list-group-item"><i class="fas fa-angle-left mr-2"></i>  Back</li></Link>            
               <Link to={`/assignments/${props.match.params.id}/paper`} ><li className="list-group-item">Question Paper</li></Link>     
               <Link to={`/assignments/${props.match.params.id}/submission`} disabled={true}><li className="list-group-item active">My Submission</li></Link>
               <Link to={`/assignments/${props.match.params.id}/answers`} ><li className="list-group-item ">Checked Sheet</li></Link>
               <Link to={`/assignments/${props.match.params.id}/topper`} ><li className="list-group-item ">Topper Copy</li></Link>
               <Link to={`/assignments/${props.match.params.id}/marks`} ><li className="list-group-item">Score Board</li></Link>

               
                {/* <Link to={`/assignments/${props.match.params.id}/answers`} ><li className="list-group-item">Checked Sheet</li></Link>
                <Link to={`/assignments/${props.match.params.id}/topper`}><li className="list-group-item">Topper Copy</li></Link> */}

</ul>
<div className="text-center my-5 py-2 text-white bg-danger">This is the view of your submitted file,you file has been submitted no changes can be done now.</div>
</main>
                {(loaded)?(

                   <div className="row">
                    
                        
                        <div className="col-md-6 col-sm-6 quiz-style text-center">
                                              
                                <div className="container-fluid " >    
                                {(window.location.hostname== "localhost")?   
                                
                                (
                                  <>
                                  <div className="container assignment_container">
                                             <div className="text-center"
                                         style={{
                                           zIndex: "999",
                                           position: "relative",
                                           width:'inherit',
                                           padding: "10px",
                                           overflow:'auto',
                                           margin:'auto',
                                           background:'black',
                                           color:'white'
                                         }}
                                       >
                                         <p>
                                           Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                         </p>
                                         <button type="button" className="nav_btn" disabled={pageNumber <= 1} onClick={previousPage}>
                                         <i class="fa fa-step-backward" aria-hidden="true"></i>
                                         </button>
                                         <button className="ml-md-2 mr-md-5 nav_btn"
                                           type="button"
                                           disabled={pageNumber >= numPages}
                                           onClick={nextPage}
                                         >
                                          <i class="fa fa-step-forward" aria-hidden="true"></i>
                                         </button>
                                         {/* <span onClick={nextPage}><i class="fa fa-step-forward" aria-hidden="true"></i></span> */}
                                 
                                         Go to{" "}
                                         <input className="mr-md-5 text-dark" size="2" onBlur={setPage} />
                                 
                                         <button className="mx-md-2 nav_btn" onClick={scaleup}><i class="fa fa-search-plus" aria-hidden="true"></i></button>
                                         <button className="nav_btn" onClick={scaledown}><i class="fa fa-search-minus" aria-hidden="true"></i></button>
                                       </div>
                                       </div>
                                       
                                 
                                <Document className="container question_container"
         
                                file={`https://cors-anywhere.herokuapp.com/${useranswer}`}
                                onLoadSuccess={onDocumentLoadSuccess}
                              >
                                <Page width={500}
                                  style={{ position: "relative" }}
                                  // onLoadSuccess={watermark}
                                  pageNumber={pageNumber}
                                  scale={scale}
                                  renderTextLayer={false}

                                />
                                {/* <div className="test">Dummy Content</div> */}
                              </Document></>):(
                                      <>
                                      <div className="container assignment_container">
                                                 <div className="text-center"
                                             style={{
                                               zIndex: "999",
                                               position: "relative",
                                               width:'inherit',
                                               padding: "10px",
                                               overflow:'auto',
                                               margin:'auto',
                                               background:'black',
                                               color:'white'
                                             }}
                                           >
                                             <p>
                                               Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                             </p>
                                             <button type="button" className="nav_btn" disabled={pageNumber <= 1} onClick={previousPage}>
                                             <i class="fa fa-step-backward" aria-hidden="true"></i>
                                             </button>
                                             <button className="ml-md-2 mr-md-5 nav_btn"
                                               type="button"
                                               disabled={pageNumber >= numPages}
                                               onClick={nextPage}
                                             >
                                              <i class="fa fa-step-forward" aria-hidden="true"></i>
                                             </button>
                                             {/* <span onClick={nextPage}><i class="fa fa-step-forward" aria-hidden="true"></i></span> */}
                                     
                                             Go to{" "}
                                             <input className="mr-md-5 text-dark" size="2" onBlur={setPage} />
                                     
                                             <button className="mx-md-2 nav_btn" onClick={scaleup}><i class="fa fa-search-plus" aria-hidden="true"></i></button>
                                             <button className="nav_btn" onClick={scaledown}><i class="fa fa-search-minus" aria-hidden="true"></i></button>
                                           </div>
                                           </div>
                                           
                                     
                               <Document className="container question_container"
         
                               file={`${useranswer}`}
                               onLoadSuccess={onDocumentLoadSuccess}
                             >
                             
                               <Page height={500}
                                 style={{ position: "relative" }}
                                 // onLoadSuccess={watermark}
                                 pageNumber={pageNumber}
                                 scale={scale}
                               />
                               {/* <div className="test">Dummy Content</div> */}
                             </Document></>)}
      
     
                                </div>
                                                      
           
                                
</div>
                         <Question student_answer={useranswer} answer={content.answer} history={props.history} id= {props.match.params.id} />  
                        {/* <div className="col-md-3 col-sm-3 p-0 left-side">
                            <ul className="list-group">
                                <li className="list-group-item sec1"><img src={chapterFile} className="pr-3" alt="" /></li>
                                <button class="btn btn-primary ">Save</button>
                            </ul>
                          

                            <ul className="list-group">
                                {questions.map(d=> <Question name={d} />)}
                                {/* <li className="list-group-item sec1">Q1. <input className="pr-3"/></li>   
                                <li className="list-group-item sec1">Q1. <input className="pr-3"/></li>
                                <li className="list-group-item sec1">Q1. <input className="pr-3"/></li>
                                <li className="list-group-item sec1">Q1. <input className="pr-3"/></li>
                                <li className="list-group-item sec1">Q1. <input className="pr-3"/></li>
                                <li className="list-group-item sec1">Q1. <input className="pr-3"/></li>
                                <li className="list-group-item sec1">Q1. <input className="pr-3"/></li>
                                <li className="list-group-item sec1">Q1. <input className="pr-3"/></li>
                                <li className="list-group-item sec1">Q1. <input className="pr-3"/></li>
                                <li className="list-group-item sec1">Q1. <input className="pr-3"/></li> */}

                            {/* </ul>
                        
                        </div> */}

    




                    </div>
):
("Loading..")
}
              
            </div>
        )
   
}
export default After_Submit