import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import License from './components/License';
// import Dashboard from './components/Dashboard';
// import Module from './components/Module';
// import Quiz from './components/Quiz';
// import QuizList from './components/QuizList';
// import QuizPage from './components/QuizPage';
// import Complaint from './components/Complaint';
// import Query from './components/Query';
// import SectionQuiz from './components/SectionQuiz';
// import SectionPage from './components/SectionPage';
// import Complete from './components/Complete';
// import StartQuiz from './components/StartQuiz';
// import AddStory from './components/AddStory';
// import Score from './components/Score';
// import Sos from './components/Sos';
// import SosPage from './components/SosPage';
// import AllStory from './components/AllStory';
// import FailPage from './components/FailPage';
// import McqQuiz from './components/McqQuiz';
// import TrueFalse from './components/TrueFalse';
// import MyStory from './components/MyStory';
// import Question from './components/Question';
// import Option from './components/Option';
// import Story from './components/Story';
// import Quiz1 from './components/Quiz1';

import Assignment from "./components/Assignments/Assignment";
// import Test_Paper from "./components/Test_Paper";
import Marks from "./components/Assignments/Total_Score";

// import Teacher_Board from "./components/Teacher_Board";
import Courses from "./components/Courses/Courses";
import Chapters from "./components/Courses/Chapters";
// import Testing from "./components/Test_Paper1";
import Answer from "./components/Assignments/View_Answer";
import Questions from "./components/Assignments/View_Question";
import Question from "./components/Assignments/Question_Paper";

import Topper from "./components/Assignments/Topper_View";
import Profile from "./components/Profile";
import Chapter_View from "./components/Courses/Chapter_View";
import After_Submit from "./components/Assignments/After_Submit";
import Notification from "./components/Notifications/Notification";

function App() {
  return (
    <Router>
     <div className="App">
       <Switch>
         {/* <License /> */}
         <Route path="/" exact component={License} />
         {/* <Dashboard /> */}
         {/* <Route path="/dashboard" component={Dashboard} />
         <Route path="/module" component={Module} />
         <Route path="/quiz" component ={Quiz} />
         <Route path="/quiz1" component ={Quiz1} />
         <Route path="/complaint" component ={Complaint} />
         <Route path="/query" component ={Query} />
         <Route path="/sectionquiz" component ={SectionQuiz} />
         <Route path="/quizlist" component ={QuizList} />
         <Route path="/quizpage" component ={QuizPage} />
         <Route path="/startquiz" component ={StartQuiz} />
         <Route path="/complete" component={Complete} />
         <Route path="/score" component={Score} />
         <Route path="/mystory" component={MyStory} />
         <Route path="/addstory" component={AddStory} />
         <Route path="/sectionpage/" component={SectionPage} />
         <Route path="/sos" component={Sos} />
         <Route path="/sospage" component={SosPage} />
         <Route path="/allstory" component={AllStory} />
         <Route path="/failpage" component={FailPage} />
         <Route path="/mcqquiz" component={McqQuiz} />
         <Route path="/truefalse" component={TrueFalse} />
         <Route path="/question" component={Question} />
         <Route path="/option" component={Option} />
         <Route path="/story" component={Story} /> */}
         <Route path="/assignment" component={Assignment} />
         {/* <Route path="/assignments/:id" exact component={Test_Paper} /> */}



          <Route path="/assignments/:id/questions" exact component={Questions} />
          <Route path="/assignments/:id/paper" exact component={Question} />

         <Route path="/assignments/:id/answers" exact component={Answer} />
         <Route path="/assignments/:id/submission" exact component={After_Submit} />

         {/* <Route path="/test"  component={Teacher_Board} /> */}


         <Route path="/courses/:id/:sid"  component={Chapter_View} />

         <Route path="/courses/:id"  component={Chapters} />

         <Route path="/courses"  component={Courses} />

          <Route path="/assignments/:id/topper"  component={Topper} />
         <Route path="/assignments/:id/marks"  component={Marks} />
 
         <Route path="/profile" component={Profile} />
         <Route path="/noti" component={Notification} />


         {/* <Route path="/tests/:id"  component={Testing} />
         <Route path="/answer/:id"  component={Answer} />
         <Route path="/question/:id"  component={Questions} /> */}
       </Switch> 
      
     </div>
    </Router>
  );
}

export default App;
