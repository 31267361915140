import React,{useState,useEffect} from 'react';
import Navbar from '../Navbar';
import chapterFile from './chapterFile.png';
import axios from 'axios';
// import SectionDetail from './SectionDetail';
import {Tab,Tabs} from 'react-bootstrap';
// import Navi from './Test_Paper'
import {Link} from "react-router-dom";
import Question from "./Answers_Questions";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const View_Answer =(props)=> {
    const [data, setData] = useState([]);

    const [content, setContent] = useState({
        question:"",
        nums:"",
        title:""
        ,
    });
    const [loaded,setLoading]=useState(false);
    const [pdf,setPdf]=useState("N/A");
    // useEffect(()=>{
    //   setLoading(true)
    // },[loaded])

    useEffect(()=>{
        const token = localStorage.Ltoken
       
			const headers = {
				"Content-Type": "application/json",
				"AUTHORIZATION": `Token ${token}`
				
        }
        axios.get( `${process.env.REACT_APP_API_URL}/learning/api/upload-checked-sheet/?testseries=${props.match.params.id}`, {headers:headers} )
        .then( response => {
          setPdf(response.data.data.checked_sheet)
           
    
      

			  axios.get( `${process.env.REACT_APP_API_URL}/learning/api/test-series-view/?test_series=${props.match.params.id}`, {headers:headers} )
			  .then( response => {
                setContent({question:response.data.data.question_paper,answer:response.data.data.test_answer_key_pdf,nums:response.data.data.no_of_questions,title:response.data.data.name})
                setLoading(true)

			  } )
			  .catch(error => {
				  console.log(error);
        });
      } );
        

      
    },[])



    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    // const refContainer = useRef(null);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }
  
    function changePage(offset) {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }
  
    function setPage(num) {
      // setPageNumber(num);
      if (
        parseInt(num.target.value) >= 1 &&
        parseInt(num.target.value) <= numPages
      )
        setPageNumber(parseInt(num.target.value));
    }
  
    function previousPage() {
      changePage(-1);
    }
  
    function nextPage() {
      changePage(1);
    }
  
    function scaleup() {
      setScale((prev) => prev + 0.5);
    }
  
    function scaledown() {
      setScale((prev) => prev - 0.5);
    }
  
   


    // useEffect(()=>
    // {
    //     const token = localStorage.Ltoken
       
    //     const headers = {
    //         "Content-Type": "application/json",
    //         "AUTHORIZATION": `Token ${token}`
            
    //       }
    //       axios.get( `${process.env.REACT_APP_API_URL}/learning/api/marks-remarks/?test_series=${props.match.params.id}`, {headers:headers} )
    //       .then( response => {
    //         setQuestion(response.data.data)
    //         setLoading(true)
    //         // if(data!=="None")
    //         // setLoading(true);
    //         // else
    //         // setLoading(false);
    //       } )
    //       .catch(error => {
    //           console.log(error);
    //       });
    // },[content])

        return (
            <div>
                {/* {(() => {
            if(data==='None') {
               
            props.history.push("/assignment")
        }
      })()} */}
               
                <Navbar />
                <main className="container-fluid mt-5">
                <h1 className="text-center">Assignment - {content.title}</h1>
                <ul className="list-group list-group-horizontal ">
                {/* <Link to={`/assignments/${props.match.params.id}/questions`}><li className="list-group-item" >Question</li></Link> */}
                <Link to={`/assignment`}><li  key="2" className="list-group-item"><i class="fas fa-angle-left mr-2"></i>  Back</li></Link>
               
                <Link to={`/assignments/${props.match.params.id}/paper`} ><li className="list-group-item">Question Paper</li></Link>
               
                <Link to={`/assignments/${props.match.params.id}/submission`} ><li className="list-group-item ">My Submission</li></Link>
                <Link to={`/assignments/${props.match.params.id}/answers`} disabled={true}><li className="list-group-item active">Checked Sheet</li></Link>
                <Link to={`/assignments/${props.match.params.id}/topper`}><li className="list-group-item">Topper Copy</li></Link>
                <Link to={`/assignments/${props.match.params.id}/marks`}><li className="list-group-item">Score Board</li></Link>

</ul>
</main>
                {/* <Navi/>  */}
                {/* https://142.93.219.223/media/chapters/lsdlkmfnksjdnfkjsdnblkvbsdnfkjvbvnsdlkvnblkjsdnfkjsanflsed.pdf */}
              
              {loaded&&pdf!='N/A'?
(
                   <div className="row mt-5">
                    

                        <div className="col-md-6 col-sm-6 quiz-style text-center">
                                              
                        <div className="container-fluid mt-5 mb-5" >    
                        {(window.location.hostname== "localhost")?          
      
      (
      <>
       <div className="container assignment_container">
            <div className="text-center"
        style={{
          zIndex: "999",
          position: "relative",
          width:'inherit',
          padding: "10px",
          overflow:'auto',
          margin:'auto',
          background:'black',
          color:'white'
        }}
      >
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <button type="button" className="nav_btn" disabled={pageNumber <= 1} onClick={previousPage}>
        <i class="fa fa-step-backward" aria-hidden="true"></i>
        </button>
        <button className="ml-md-2 mr-md-5 nav_btn"
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
         <i class="fa fa-step-forward" aria-hidden="true"></i>
        </button>
        {/* <span onClick={nextPage}><i class="fa fa-step-forward" aria-hidden="true"></i></span> */}

        Go to{" "}
        <input className="mr-md-5 text-dark" size="2" onBlur={setPage} />

        <button className="mx-md-2 nav_btn" onClick={scaleup}><i class="fa fa-search-plus" aria-hidden="true"></i></button>
        <button className="nav_btn" onClick={scaledown}><i class="fa fa-search-minus" aria-hidden="true"></i></button>
      </div>
      </div>
      <Document className="container question_container"
         
      file={`https://cors-anywhere.herokuapp.com/${pdf}`}
      onLoadSuccess={onDocumentLoadSuccess}
    >
        
      <Page width={500}
        style={{ position: "relative" }}
        // onLoadSuccess={watermark}
        pageNumber={pageNumber}
        scale={scale}
        renderTextLayer={false}

      />
      {/* <div className="test">Dummy Content</div> */}
    </Document></>):(
     <>
      <div className="container assignment_container">
            <div className="text-center"
        style={{
          zIndex: "999",
          position: "relative",
          width:'inherit',
          padding: "10px",
          overflow:'auto',
          margin:'auto',
          background:'black',
          color:'white'
        }}
      >
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <button type="button" className="nav_btn" disabled={pageNumber <= 1} onClick={previousPage}>
        <i class="fa fa-step-backward" aria-hidden="true"></i>
        </button>
        <button className="ml-md-2 mr-md-5 nav_btn"
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
         <i class="fa fa-step-forward" aria-hidden="true"></i>
        </button>
        {/* <span onClick={nextPage}><i class="fa fa-step-forward" aria-hidden="true"></i></span> */}

        Go to{" "}
        <input className="mr-md-5 text-dark" size="2" onBlur={setPage} />

        <button className="mx-md-2 nav_btn" onClick={scaleup}><i class="fa fa-search-plus" aria-hidden="true"></i></button>
        <button className="nav_btn" onClick={scaledown}><i class="fa fa-search-minus" aria-hidden="true"></i></button>
      </div>
      </div>
     <Document className="container question_container"

     file={`${pdf}`}
     onLoadSuccess={onDocumentLoadSuccess}
   >
     
     <Page height={500}
       style={{ position: "relative" }}
       // onLoadSuccess={watermark}
       pageNumber={pageNumber}
       scale={scale}
     />
     {/* <div className="test">Dummy Content</div> */}
   </Document></>)}

                                </div>
                                                      
                                
                             
                                
                                </div>
                           
                        <div className="col-md-6 col-sm-6 p-0 left-side" >
                          
                          <Question  checkedsheet={pdf} history={props.history} id= {props.match.params.id} answer={content.answer}/>

                            {/* <ul className="list-group">
                                {questions.map(d=> <li className="list-group-item sec1">Q:{d.question_no}: <textarea value={d.answer} disabled={true} rows="8" style={{width:"100%"}}/> 
                                <p>Marks: {d.marks}</p> 
                                <p>Remarks:{d.remarks}</p> </li>)}
                               
                            </ul> */}
                            {/* <a href={`${process.env.REACT_APP_API_URL}${content.question}`} download><p>Download Answer Key </p></a> */}

                        </div>
                    </div>

):(<h3 className="text-center mt-5">Answer not checked yet..</h3>)}
              
            </div>
        )
         
  
}
export default View_Answer