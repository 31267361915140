import React from 'react';
import './Module.css';
import chapterFile from './chapterFile.png';


const heading=(props) => {
    console.log(props)
    return (   
        
        <div className="row">
            <div className="col chapters" id={props.id}>{props.children}</div>
        </div>
 
    )
}
export default heading