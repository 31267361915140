import React,{useState,useEffect} from 'react';
import Navbar from '../Navbar';
import chapterFile from './chapterFile.png';
import axios from 'axios';
// import SectionDetail from './SectionDetail';
import {Tab,Tabs} from 'react-bootstrap';
// import Navi from './Test_Paper'
import {Link} from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Topper_View =(props)=> {
    const [content, setContent] = useState({
        pdf:"",
    });
    const [loaded,setLoading]=useState(false);
    const [title,setTitle]=useState(null);
    useEffect(()=>{
        const token = localStorage.Ltoken
       
			const headers = {
				"Content-Type": "application/json",
				"AUTHORIZATION": `Token ${token}`
				
        }
        
        axios.get( `${process.env.REACT_APP_API_URL}/learning/api/test-series-view/?test_series=${props.match.params.id}`, {headers:headers} )
			  .then( response => {
                setTitle({title:response.data.data.name})

        } )
        
			  axios.get( `${process.env.REACT_APP_API_URL}/learning/api/topper-copy/?id=${props.match.params.id}`, {headers:headers} )
			  .then( response => {
                setContent({pdf:response.data.data.topper_copy})
                if (response.data.data.topper_copy != 'N/A')
                  setLoading(true);
			  } )
			  .catch(error => {
				  console.log(error);
			  });
    },[])

    

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    // const refContainer = useRef(null);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }
  
    function changePage(offset) {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }
  
    function setPage(num) {
      // setPageNumber(num);
      if (
        parseInt(num.target.value) >= 1 &&
        parseInt(num.target.value) <= numPages
      )
        setPageNumber(parseInt(num.target.value));
    }
  
    function previousPage() {
      changePage(-1);
    }
  
    function nextPage() {
      changePage(1);
    }
  
    function scaleup() {
      setScale((prev) => prev + 0.5);
    }
  
    function scaledown() {
      setScale((prev) => prev - 0.5);
    }
  
   


if(loaded){
        return (
            <div>
                <Navbar />
                <main className="container-fluid mt-5">
                <h1 className="text-center">Assignment - {title!=null&& title.title}</h1>
                <ul className="list-group list-group-horizontal ">
                {/* <Link to={`/assignments/${props.match.params.id}/questions`}><li className="list-group-item" >Question</li></Link> */}
                <Link to={`/assignment`}><li  key="2" className="list-group-item"><i class="fas fa-angle-left mr-2"></i>  Back</li></Link>
               
               <Link to={`/assignments/${props.match.params.id}/paper`} ><li className="list-group-item">Question Paper</li></Link>
              
               <Link to={`/assignments/${props.match.params.id}/submission`} ><li className="list-group-item ">My Submission</li></Link>
               <Link to={`/assignments/${props.match.params.id}/answers`} ><li className="list-group-item ">Checked Sheet</li></Link>
               <Link to={`/assignments/${props.match.params.id}/topper`} disabled={true}><li className="list-group-item active">Topper Copy</li></Link>
               <Link to={`/assignments/${props.match.params.id}/marks`}><li className="list-group-item">Score Board</li></Link>



</ul>
</main>
                     
                   <div className="row">
                   <div className="col-md-3 mt-5 ml-5">
                   <a  rel="noopener noreferrer" href={`${content.pdf}`} target="_blank" download className="download_button"><i class="fas fa-file-download fa-2x" style={{float:"left",color:"white"}}></i>Topper Copy</a>
                  
                   </div>
                   <div className="col-md-12 col-sm-12 quiz-style text-center">

                   
                                <div className="container-fluid mt-5" >   
                               {(window.location.hostname== "localhost")?   
                                
                                (<>
       <div className="container chapter_container">

<div className="text-center"
        style={{
          zIndex: "999",
          position: "relative",
          width:'inherit',
          padding: "10px",
          overflow:'auto',
          margin:'auto',
          background:'black',
          color:'white'
        }}
      >
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <button type="button" className="nav_btn" disabled={pageNumber <= 1} onClick={previousPage}>
        <i class="fa fa-step-backward" aria-hidden="true"></i>
        </button>
        <button className="ml-md-2 mr-md-5 nav_btn"
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
         <i class="fa fa-step-forward" aria-hidden="true"></i>
        </button>
        {/* <span onClick={nextPage}><i class="fa fa-step-forward" aria-hidden="true"></i></span> */}

        Go to{" "}
        <input className="mr-md-5" size="2" onBlur={setPage} />

        <button className="mx-md-2 nav_btn" onClick={scaleup}><i class="fa fa-search-plus" aria-hidden="true"></i></button>
        <button className="nav_btn" onClick={scaledown}><i class="fa fa-search-minus" aria-hidden="true"></i></button>
      </div>
      </div>


                                <Document className="container mt-5 test"
         
                                file={`https://cors-anywhere.herokuapp.com/${content.pdf}`}
                                onLoadSuccess={onDocumentLoadSuccess}
                              >
                           
                                <Page width={500}
                                  style={{ position: "relative" }}
                                  // onLoadSuccess={watermark}
                                  pageNumber={pageNumber}
                                  scale={scale}
                                  renderTextLayer={false}

                                />
                                {/* <div className="test">Dummy Content</div> */}
                              </Document></>
                              
                              ):(
                                <>
                                   <div className="container chapter_container">

<div className="text-center"
        style={{
          zIndex: "999",
          position: "relative",
          width:'inherit',
          padding: "10px",
          overflow:'auto',
          margin:'auto',
          background:'black',
          color:'white'
        }}
      >
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <button type="button" className="nav_btn" disabled={pageNumber <= 1} onClick={previousPage}>
        <i class="fa fa-step-backward" aria-hidden="true"></i>
        </button>
        <button className="ml-md-2 mr-md-5 nav_btn"
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
         <i class="fa fa-step-forward" aria-hidden="true"></i>
        </button>
        {/* <span onClick={nextPage}><i class="fa fa-step-forward" aria-hidden="true"></i></span> */}

        Go to{" "}
        <input className="mr-md-5" size="2" onBlur={setPage} />

        <button className="mx-md-2 nav_btn" onClick={scaleup}><i class="fa fa-search-plus" aria-hidden="true"></i></button>
        <button className="nav_btn" onClick={scaledown}><i class="fa fa-search-minus" aria-hidden="true"></i></button>
      </div>
      </div>

                               <Document className="container test"
         
                               file={`${content.pdf}`}
                               onLoadSuccess={onDocumentLoadSuccess}
                             >
                               
                               <Page height={900}
                                 style={{ position: "relative" }}
                                 // onLoadSuccess={watermark}
                                 pageNumber={pageNumber}
                                 scale={scale}
                                 renderTextLayer={false}
                               />
                               {/* <div className="test">Dummy Content</div> */}
                             </Document></>)}
      
     
                                </div>
                                </div>       
                        </div>
                    

              
            </div>
        )
}
else{
    return(
    <div>
    <Navbar />
    <main className="container-fluid mt-5">
    <h1 className="text-center">Assignment - {title!=null&& title.title}</h1>

    <ul className="list-group list-group-horizontal ">
    <Link to={`/assignment`}><li  key="2" className="list-group-item"><i class="fas fa-angle-left mr-2"></i>  Back</li></Link>  
    <Link to={`/assignments/${props.match.params.id}/paper`} ><li className="list-group-item">Question Paper</li></Link>        
    <Link to={`/assignments/${props.match.params.id}/submission`} ><li className="list-group-item ">My Submission</li></Link>
    <Link to={`/assignments/${props.match.params.id}/answers`} ><li className="list-group-item ">Checked Sheet</li></Link>
    <Link to={`/assignments/${props.match.params.id}/topper`} disabled={true}><li className="list-group-item active">Topper Copy</li></Link>
    <Link to={`/assignments/${props.match.params.id}/marks`}><li className="list-group-item">Score Board</li></Link>

</ul>
</main>
<div className="container text-center mt-5">
   <h2>No content found..</h2> 
</div>
</div>
    )
}
   
}
export default Topper_View