import React,{useState,useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import {NavLink,Link} from "react-router-dom"

import Navbar from '../Navbar';
import axios from 'axios';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ReactPlayer from 'react-player'
import "./Chapters.css"
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const drawerWidth = 240;
const watermark_data = localStorage.mobile!='undefined'?
localStorage.name+localStorage.email+localStorage.mobile :localStorage.name+localStorage.email


const useStyless = makeStyles((theme) => ({
  roots: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Chapter_View =(props)=> {
    

    const [courses,setCourses] = useState([]);
    const [section,setSection] = useState([{
        active: false,
        content_pdf: "N/A",
        created: "",
        description: "",
        id: '',
        language: "",
        title: "",
        updated: "",
        video_url: "",
    }]);
    const [show,setShow] = useState("");
    const [name,setName] = useState("");
    const [data,showDatas] = useState(-1);

    const [load,setLoad] = useState(false);


useEffect(()=>
{
    if(localStorage.getItem('sid')!=props.match.params.sid)
    {
        localStorage.setItem('sid',props.match.params.sid)
        window.location.reload();

    }
},[props.match.params.sid])




const [numPages, setNumPages] = useState(null);
const [pageNumber, setPageNumber] = useState(1);
const [scale, setScale] = useState(1);
// const refContainer = useRef(null);

function onDocumentLoadSuccess({ numPages }) {
  setNumPages(numPages);
  setPageNumber(1);
}

function changePage(offset) {
  setPageNumber((prevPageNumber) => prevPageNumber + offset);
}

function setPage(num) {
  // setPageNumber(num);
  if (
    parseInt(num.target.value) >= 1 &&
    parseInt(num.target.value) <= numPages
  )
    setPageNumber(parseInt(num.target.value));
}

function previousPage() {
  changePage(-1);
}

function nextPage() {
  changePage(1);
}

function scaleup() {
  setScale((prev) => prev + 0.5);
}

function scaledown() {
  setScale((prev) => prev - 0.5);
}

function watermark() {
  // var container = document.getElementsByClassName(
  //   "react-pdf__Page__canvas"
  // )[0];
  // var ctx = container.getContext("2d");
  // ctx.font = "30px Arial";
  // ctx.fillText("Hello World", 10, 50);
  const watermark_data = localStorage.mobile!="null"?
  localStorage.name+localStorage.email+localStorage.mobile :localStorage.name+localStorage.email

  var container = document.getElementsByClassName("react-pdf__Page")[0];
  var origCanvas = document.getElementsByClassName(
    "react-pdf__Page__canvas"
  )[0];
  if (document.getElementsByClassName("watermark").length >= 1)
    // if (document.getElementById("watermark"))
    //   document.getElementById("watermark").remove();
    document
      .getElementsByClassName("watermark")[0]
      .setAttribute("style", "display:none");

  var wmCanvas = document.createElement("canvas");
  wmCanvas.id = "watermark";
  wmCanvas.classList.add("watermark");
  wmCanvas.width = origCanvas.width;
  wmCanvas.height = origCanvas.height;
  // wmCanvas.setAttribute("style", "position:absolute;border:1px solid black");
  // wmCanvas.setAttribute("style", "position:absolute;");
  wmCanvas.setAttribute("style", `position:absolute;display:block;user-select: none;width:${origCanvas.style.width};height:${origCanvas.style.height}`);

  // if (container.firstChild)
  //   container.insertBefore(wmCanvas, container.firstChild);
  // else container.appendChild(wmCanvas);
  container.insertBefore(wmCanvas, container.firstChild);
  var wmContext = wmCanvas.getContext("2d");
  wmContext.globalAlpha = 0.5;
  // setup text for filling
  wmContext.font = "30px Comic Sans MS";
  wmContext.fillStyle = "red";
  // get the metrics with font settings
  var metrics = wmContext.measureText("WaterMark Demo");
  var width = metrics.width;
  // height is font size
  var height = 72;
  // change the origin coordinate to the middle of the context
  wmContext.translate(origCanvas.width / 2, origCanvas.height / 2);
  // rotate the context (so it's rotated around its center)
  wmContext.rotate(-Math.atan(origCanvas.height / origCanvas.width));
  // as the origin is now at the center, just need to center the text
  wmContext.fillText(`${watermark_data}`, -width / 2, height / 2);

  // var origCanvas = document.getElementsByClassName(
  //   "react-pdf__Page__canvas"
  // )[0];
  // var wmContext = origCanvas.getContext("2d");
  // wmContext.globalAlpha = 0.5;
  // // setup text for filling
  // wmContext.font = "72px Comic Sans MS";
  // wmContext.fillStyle = "red";
  // // get the metrics with font settings
  // var metrics = wmContext.measureText("WaterMark Demo");
  // var width = metrics.width;
  // // height is font size
  // var height = 72;

  // wmContext.translate(origCanvas.width / 2, origCanvas.height / 2);
  // // rotate the context (so it's rotated around its center)
  // wmContext.rotate(-Math.atan(origCanvas.height / origCanvas.width));
  // // as the origin is now at the center, just need to center the text
  // wmContext.fillText("WaterMark Demo", width / 2, height);
}







     useEffect(()=>
     {
      
         const token = localStorage.Ltoken
             const headers = {
                 "Content-Type": "application/json",
                 "AUTHORIZATION": `Token ${token}`
                 
               }


               axios.get(`${process.env.REACT_APP_API_URL}/learning/api/chapter/?course=${props.match.params.id}`, {headers:headers})
               .then( response => {
                   // console.log(response.data)
                 setCourses(response.data.data)
       
       
                //    console.log( response.data.data);
               } )
               .catch(error => {
                   console.log(error);
               });      
              
     },[])



     useEffect(()=>
     {
       
        const token = localStorage.Ltoken
        const headers = {
            "Content-Type": "application/json",
            "AUTHORIZATION": `Token ${token}`
            
          }

        //   const ids=courses.map((data)=>data.chapter.map((sections)=>{
        //    return (sections.section)
        //   }
        //     ))
        //     ids.map((data)=>
        //       console.log(data.id)
        //         )
           
            // console.log(d)


        axios.get(`${process.env.REACT_APP_API_URL}/learning/api/section/?section=${props.match.params.sid}`, {headers:headers} )
        .then( response => {
            // console.log(response.data)
          setSection(response.data.data)
            setLoad(true);


         //    console.log( response.data.data);
        } )
        .catch(error => {
            console.log(error);
        });

     },[courses])




     useEffect(()=>
     {
       if(window.location.hostname!= "localhost")
        
       {
         setShow(section.map((data)=>

        <div>
        <div style={{ textAlign: 'center' }}>
        {/* <p>{data.title}</p> */}
        {data.video_url!='N/A'?(
          //  <iframe
          //   width="560"
          //    height="315"
          //    src={data.video_url}
          //    frameborder="0"
          //    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          //      allowfullscreen title="youtube">
          //  </iframe>
          <div className="container mt-5">
          <ReactPlayer controls={true} className="m-auto" style={{margin:'auto'}} url={data.video_url}/>
          </div>

        ):('')}
        {/* {data.content_pdf!='N/A'?(
           <PDFViewer  document={{ url: `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_API_URL}${data.content_pdf}`,
   }} />):('')} */}
   {/*data.content_pdf!='N/A'?(
        //    <PDFViewer  document={{ url: `${data.content_pdf}`,
  //       <PDFViewer 
  //       watermark={{
  //         text: watermark_data,
  //         diagonal: true,
  //         opacity: '0.2',
  //         size: '30',
  //         color: 'red',
  //     }}
  //       //  navbarOnTop={true} document={{ url: `https://cors-anywhere.herokuapp.com/${data.content_pdf}`,
  //       navbarOnTop={true} document={{ url: `${data.content_pdf}`,

  //       // navbarOnTop={true} document={{ url: `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_API_URL}/${data.content_pdf}`,

  //  }}
  //   />

   ):('')*/}

           </div>
        
        </div>));
     }
        else
        {
        setShow(section.map((data)=>

        <div>
        <div style={{ textAlign: 'center' }}>
        {/* <p>{data.title}</p> */}
        {data.video_url!='N/A'?(
          //  <iframe
          //   width="560"
          //    height="315"
          //    src={data.video_url}
          //    frameborder="0"
          //    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          //      allowfullscreen title="youtube">
          //  </iframe>
          <div className="container mt-5 mobile_container_pdf">
          <ReactPlayer controls={true} className="m-auto" style={{margin:'auto'}} url={data.video_url}/>
          </div>
        ):('')}
        {/* {data.content_pdf!='N/A'?(
           <PDFViewer  document={{ url: `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_API_URL}${data.content_pdf}`,
   }} />):('')} */}
   {data.content_pdf!='N/A'?(
        //    <PDFViewer  document={{ url: `${data.content_pdf}`,
  //       <PDFViewer 
  //       watermark={{
  //         text: watermark_data,
  //         diagonal: true,
  //         opacity: '0.2',
  //         size: '30',
  //         color: 'red',
  //     }}
  //        navbarOnTop={true} document={{ url: `https://cors-anywhere.herokuapp.com/${data.content_pdf}`,
  //       // navbarOnTop={true} document={{ url: `${data.content_pdf}`,

  //       // navbarOnTop={true} document={{ url: `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_API_URL}/${data.content_pdf}`,

  //  }} />
   ""
   ):('')}
           </div>
        
        </div>)
        );  
}
        return () => {
            setLoad(false)
          };
     },[section])



     useEffect(()=>
     {
        setLoad(true);
     },[show])
    
//      const section_data=section.map((data)=>
//      <div><p>{data.title}</p>
//         <div style={{ textAlign: 'center' }}>
//         <iframe
//          width="560"
//           height="315"
//           src={data.video_url}
//           frameborder="0"
//           allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//             allowfullscreen title="youtube">
//         </iframe>

//         <PDFViewer  document={{ url: `${process.env.REACT_APP_API_URL}${data.content_pdf}`,
// }} />
//         </div>
     
//      </div>
     
//      );

const classess = useStyless();
const [opens, setOpenn] = React.useState(false);
const [titles, setTitle] = React.useState("");

const handleClicks = (title) => {
  title===titles?setOpenn(!opens):setOpenn(true)
  setTitle(title)
};

     


  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
if(load)
  return (
    <div>

    <Navbar />

    <div className={classes.root}>
      <CssBaseline />
      <AppBar  style={{zIndex:"5",
      backgroundColor:"red"}}
        position="static"
        // className={clsx(classes.appBar, {
        //   [classes.appBarShift]: open,
        // })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" noWrap>
            Sections
          </Typography>
        </Toolbar>
      </AppBar>
</div>
      {/* <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer> */}




<Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
       <Link to="/courses/" style={{textAlign:"left",position: "relative",
    top: "42px",
    left: "8px"}}>
        Home
        </Link>
        <div className={classes.drawerHeader}>
        

          <IconButton onClick={handleDrawerClose}>
          
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />





        <List>
        {
    courses.map(data => {
       
       
            return (
                <>
               

              <ListItem button id={data.title} onClick={()=>handleClicks(data.title)}>
             <ListItemText primary={data.title} />
        {opens ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

            {
                data.section.map(d => (
                  
                    <Collapse in={titles===data.title && opens } timeout="auto" unmountOnExit>

                    <List component="div" disablePadding>
                    <NavLink  style={{textTransform:"none"}}  activeStyle={{
                   
                   color: "red"
                 }}to={`/courses/${props.match.params.id}/${d.id}`}> 
                  <ListItem button className={classess.nested}>
                  <p >{d.title}</p>
          </ListItem>
          </NavLink>
        </List>
      </Collapse>
      
                  

                ))
            }
            
            <Divider />
            </>
            )

        })
    }
        </List>
       
      
        
      </Drawer>


<div className="container">
      {/* <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      > */}
        {/* <div className={classes.drawerHeader} />
        <Typography paragraph>
        show
        </Typography> */}
        <div className={classes.drawerHeader} />
      
     
        
        <div className="container mt-5 mobile_container_pdf" style={{overflow:'auto'}}>
    

      {section.map(data=>
      <>
      <p className="text-center">{data.title}</p>

      {data.content_pdf!='N/A'&&
      <>
      <div className="container chapter_container mobile_container_pdf">
            <div className="text-center navbar_pdf"
        // style={{
        //   zIndex: "999",
        //   position: "absolute",
        //   width:'inherit',
        //   padding: "10px",
        //   overflow:'auto',
        //   margin:'auto',
        //   background:'black',
        //   color:'white'
        // }}
      >
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <button type="button" className="nav_btn" disabled={pageNumber <= 1} onClick={previousPage}>
        <i class="fa fa-step-backward" aria-hidden="true"></i>
        </button>
        <button className="ml-md-2 mr-md-5 nav_btn"
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
         <i class="fa fa-step-forward" aria-hidden="true"></i>
        </button>
        {/* <span onClick={nextPage}><i class="fa fa-step-forward" aria-hidden="true"></i></span> */}

        Go to{" "}
        <input className="mr-md-5 text-dark" size="2" onBlur={setPage} />

        <button className="mx-md-2 nav_btn" onClick={scaleup}><i class="fa fa-search-plus" aria-hidden="true"></i></button>
        <button className="nav_btn" onClick={scaledown}><i class="fa fa-search-minus" aria-hidden="true"></i></button>
      </div>
      </div>
      <Document className="container test mobile_container_pdf"
         
          // file={`https://cors-anywhere.herokuapp.com/${data.content_pdf}`}
          file={`${data.content_pdf}`}

          onLoadSuccess={onDocumentLoadSuccess}
        >
    
          <Page width={942}
            style={{ position: "relative" }}
            // onLoadSuccess={watermark}
            pageNumber={pageNumber}
            scale={scale}
            onRenderSuccess={watermark}
            renderTextLayer	={false}
          />
          {/* <div className="test">Dummy Content</div> */}
        </Document>
        </>}
        </>
        )}
       
        </div>
     
        {show}
       
      {/* </main> */}
    </div>
    </div>

  );
  else{
      return(
          <>

      </>)
  }
}

        
export default Chapter_View