import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import Navbar from './Navbar';
import Course_List from "./Courses/Courses_List"
import Cards from "./Assignments/Assignment_Card";
import Assignments from "./Assignments/Test_Series";

const Profile=(props) => {
	
	// const [assignment,setAssignment] = useState([]);
	const [courses,setCourses] = useState([]);

	useEffect(()=>
    {
        const token = localStorage.Ltoken
			const headers = {
				"Content-Type": "application/json",
				"AUTHORIZATION": `Token ${token}`
				
              }
        
			//   axios.get( `${process.env.REACT_APP_API_URL}/learning/api/test-series/`, {headers:headers} )
			//   .then( response => {
			// 	  setAssignment(response.data.data)
            //       console.log( response.data.data);
			//   } )
			//   .catch(error => {
			// 	  console.log(error);
            //   });

              axios.get( `${process.env.REACT_APP_API_URL}/learning/api/all-courses/`, {headers:headers} )
			  .then( response => {
				  setCourses(response.data.data)
                  console.log( response.data.data);
			  } )
			  .catch(error => {
				  console.log(error);
			  });
	},[])
	

	// useEffect(()=>
	// {
	// 	setData(assignment.map((data)=>
	// 	<>
	// 		<div className="col-md-2 mt-3">
	// 		<div className="card">
  	// 		<div className="card-body">{test.name}</div>
  	// 		</div>
	// 		</div>
	// 	</>
	// 	))
	// },[assignment])
    return (   
        <React.Fragment>
			<Navbar/>
            <div className="container mb-5">
                <h1 className="text-center mt-5 mb-5">My Courses</h1>
              
                <div className="row mb-5">
                {courses.length>0&&courses.map(data=>
                  <>
                  <Course_List name={data.course_name} id={data.id}/>
               
                </>
              
                )}
                </div>
				{courses.length==0&&<p className="text-center">No courses available..</p>}



                <h1 className="text-center mb-5" style={{marginTop:"5rem"}}>My Assignments</h1>
               
                {/* {assignment.map((data)=>
			<Cards name={data.name} id={data.id} ques={data.no_of_questions}/>)} */}

			<Assignments/>
            </div>
	
        </React.Fragment>
    )
}
export default Profile