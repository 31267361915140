import React, { Component } from 'react';
import './License.css';
import licenseLogo from '../img/logo.png';
import axios from 'axios';
import backgroundImage from "../img/background.jpg"

let styles = {
 
  backgroundImage: 'url(' + backgroundImage + ')',
  height: "100vh",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
class License extends Component {
    
  state = {
    license:'',
    error:''
  }

    enterValue = e => {
      this.setState({[e.target.name]: e.target.value})
    }

     componentDidMount(){
    //  document.addEventListener('contextmenu',(event) =>event.returnValue=true)
      // localStorage.removeItem('Ltoken')
      localStorage.clear();
      const items = { ...localStorage };

      console.log(items)
     }
    componentWillUnmount(){
      document.addEventListener('contextmenu', event => event.preventDefault());

    }
    onFormSubmit = (event) => {
      this.setState({
        error:''
      })
      event.preventDefault();
      // this.props.history.push("/dashboard/");
      const headers = {
				"Content-type" : "application/json"

      };
      const data = {
        'license':this.state.license.trim()
      }
			axios.post(`${process.env.REACT_APP_API_URL}/license/api/license/login/`, data ,{headers:headers } )
			.then(response => {
                console.log(response.data.data);
                if(response.data.data.token){
                  localStorage.setItem('Ltoken',response.data.data.token)
                  localStorage.setItem('lic',response.data.data.message)
                  localStorage.setItem('name',response.data.data.name)
                  localStorage.setItem('email',response.data.data.email)
                  localStorage.setItem('mobile',response.data.data.mobile)
                  localStorage.setItem('student_logo',response.data.data.logo)
                  // localStorage.setItem('Ltoken',response.data.token)
                  // localStorage.setItem('lic',response.data.message)
                  // localStorage.setItem('name',response.data.name)
                  // localStorage.setItem('email',response.data.email)
                  // localStorage.setItem('mobile',response.data.mobile)
                  // localStorage.setItem('student_logo',response.data.logo)

                  this.props.history.push("/courses/");
                }
                else{
                  this.setState({
                    error:'Invalid license'
                  })
                }
			})
				.catch(error =>{
					console.log(error);
					this.setState({
            error:'There seems to be some error! please try again later'
          })
            });
      }
    
    render() {
     
        return (


//           <div>
//           <div className="container-fluid " style={styles}>
// <div className="row license-number m-auto p-5" style={{border:"2px solid red"}}>
//   <div className="col-lg-6 p-5 m-auto">
   
//     </div>
//         <div className="col-lg-6 m-auto license-number">
       

//         <center>
    
//       <p>LMS Login</p>

//       <form onSubmit={(event) => this.onFormSubmit(event)}>
//             <div className="form-group">
//               <label htmlFor="license">Key Number</label>
//               <input type="text" name="license" required="required" 
//               className="form-control" id="license" 
//               placeholder="Enter your key"
//               value={this.state.license}
//               onChange={this.enterValue}/>
//             </div>
//   <h5>{this.state.error}</h5>
//             <button type="submit" className="btn btn-style btn-block">LOGIN</button>
//         </form>
           

//     </center>
//       </div>
//     </div>

//   </div>
// </div>
     

<div className="container-fluid" style={styles}>
		<div className="row main-content  text-center">
			{/* <div className="col-md-4 text-center company__info">
				<span className="company__logo"><h2><span ><img src={licenseLogo} alt="test"/></span></h2></span>
			</div> */}
			<div className="col-md-12 col-xs-12 col-sm-12 login_form ">
				<div className="container-fluid">
					<div className="row">
					</div>
					<div className="container">

              <form onSubmit={(event) => this.onFormSubmit(event)}>
							<div className="row">
              <input type="text" name="license" required="required"  
                    className="form__input" id="license" 
                    placeholder="Enter your key"
                    value={this.state.license.trim()}
                    onChange={this.enterValue}/>
                 
        <h5>{this.state.error}</h5>
							</div>
						
							
							<div className="row">
                <button type="submit" className="btn">LOGIN</button>
							</div>
						</form>
					</div>
				
				</div>
			</div>
		</div>
	</div>






//             <div>
//                 <div className="container-fluid" style={styles}>
//     <div className="row license-number">
//         <div className="col p-5 m-auto">
//           <center>
//             <img src={backgroundImage} alt=""/>
//             <p>LMS Login</p>
//           </center>
//             <div className="row">
//               <div className="col-md-4 m-auto">
             

        //       <form onSubmit={(event) => this.onFormSubmit(event)}>
        //           <div className="form-group">
        //             <label htmlFor="license">Key Number</label>
        //             <input type="text" name="license" required="required" 
        //             className="form-control" id="license" 
        //             placeholder="Enter your key"
        //             value={this.state.license}
        //             onChange={this.enterValue}/>
        //           </div>
        // <h5>{this.state.error}</h5>
        //           <button type="submit" className="btn btn-style btn-block">LOGIN</button>
        //       </form>
                 
//             </div>
//           </div>

//         </div>
//     </div>
// </div>
//             </div>
        )
    }
}

export default License
