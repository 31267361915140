import React,{useState,useEffect} from 'react';
import Navbar from '../Navbar';
import chapterFile from './chapterFile.png';
import axios from 'axios';
import SectionDetail from '../SectionDetail';
import Headline from '../heading';
import {Tab,Tabs} from 'react-bootstrap';
import {Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';


const Answers_Questions =(props)=> {

  
    
    const [data, setData] = useState([]);
    const [pdf, setPdf] = useState([]);
    const [total, setTotal] = useState([]);
    const [useranswer, setUserAnswer] = useState("N/A");



    useEffect(()=>{
        const token = localStorage.Ltoken
           
			const headers = {
				"Content-Type": "application/json",
				"AUTHORIZATION": `Token ${token}`
				
			  }
              axios.get( `${process.env.REACT_APP_API_URL}/learning/api/marks-remarks/?test_series=${props.id}`, {headers:headers} )
			  .then( response => {
                  setData(response.data.total_marks.data)
                  setTotal(response.data.total_marks.total_marks.marks__sum)
			  } )
			  .catch(error => {
				  console.log(error);
        });
        

        // axios.get( `${process.env.REACT_APP_API_URL}/learning/api/upload-pdf-answers/?test_series=${props.id}`, {headers:headers} )
        // .then( response => {
        //         const x=response.data.data.map(d=>d.answersheet)
        //         setUserAnswer(x[0])
        //          console.log("MY RESPONSE",x[0])
  
                   
        // } )

        // axios.get( `${process.env.REACT_APP_API_URL}/learning/api/upload-checked-sheet/?testseries=${props.id}`, {headers:headers} )
        // .then( response => {
        //   setPdf(response.data.data.checked_sheet)
           
    
        // } )
    },[])
  
   


        return (

            <>

              <ul className="list-group">
                <div className="row mt-5">

                {/* {props.answer!='N/A'&&<div className="col-md-3"><a  rel="noopener noreferrer" href={`${props.answer}`} target="_blank" download className="download_button"><i class="fas fa-file-download fa-2x" style={{float:"left",color:"white"}}></i>Answer Key</a>
                            </div>} */}
                {props.checkedsheet!='N/A' &&<div className="col-md-3"> <a  rel="noopener noreferrer" href={`${props.checkedsheet}`} target="_blank" download className="download_button"><i class="fas fa-file-download fa-2x" style={{float:"left",color:"white"}}></i>Checked Answer</a>
                           </div>} 
                            {/* {props.answer!='N/A'&&<div className="col-md-3"><a  rel="noopener noreferrer" href={`${props.answer}`} target="_blank" download className="download_button"><i class="fas fa-file-download fa-2x" style={{float:"left",color:"white"}}></i>Answer Key</a>
                            </div>}
                            {props.checkedsheet!='N/A' &&<div className="col-md-3"> <a  rel="noopener noreferrer" href={`${props.checkedsheet}`} target="_blank" download className="download_button"><i class="fas fa-file-download fa-2x" style={{float:"left",color:"white"}}></i>Checked Answer</a>
                           </div>} 
                            {useranswer!='N/A' &&<div className='col-md-3'> <a  rel="noopener noreferrer" href={`${useranswer}`} target="_blank" download className="download_button"><i class="fas fa-file-download fa-2x" style={{float:"left",color:"white"}}></i>My answersheet</a></div>} */}
                            </div>
                            </ul>
              <div className="left-side col mt-5 " style={{height:"100vh",overflow:"auto"}} >
          
            <ul className="list-group mb-5">
              <li style={{listStyle:'none'}}>Your total marks: {total}</li>
                {data.map(d=> <li className="list-group-item sec1">Q:{d.question_no}: <textarea value={d.answer} disabled={true} rows="8" style={{width:"100%"}}/> 
                <p>Marks: {d.marks}</p> 
                <p>Remarks:{d.remarks}</p> </li>)}               
                </ul>
             </div>         
           </>
        )
   
}
export default Answers_Questions;