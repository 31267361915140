import React,{useState} from 'react';
import chapterFile from './chapterFile.png';
import { Link } from 'react-router-dom';
import Headline from '../heading';
import Navbar from '../Navbar';
import {Tab,Tabs} from 'react-bootstrap';
import Test_Series from "./Test_Series";
import Toppers from "./Toppers_Copy";
const Assignment=(props) => {
    const [key, setKey] = useState('Test');
    return (   
        <React.Fragment>
		<div>
			<Navbar/>
			<Headline>Assignments</Headline>
            
            <div className="container">
            <Test_Series/>

            {/* <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="Test" title="Test Series">
        <Test_Series/>
      </Tab>
      <Tab eventKey="Topper" title="Topper copy">
      <Toppers/>
      </Tab>
     
    </Tabs> */}
		</div>
        </div>
        </React.Fragment>
    )
}
export default Assignment