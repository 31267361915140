import React, { Component } from 'react';
import './Navbar.css';
import logo from './../img/logo.png';
import profile from './profile.png';
import sos from './sos.svg';
import { Link } from 'react-router-dom';
import Language from './Language';
import { useTranslation } from 'react-i18next';
// import {Navbar,NavDropdown,Nav} from "react-bootstrap"
import axios from 'axios';


function Navbar(props) {
	

		const { t, i18n } = useTranslation();
		function handleClick(lang) {
			var language = 'English';
			i18n.changeLanguage(lang);
			if(lang == 'en') language = 'English';
			else language = 'Hindi';
			const token = localStorage.Ltoken
		const headers = {
			"Content-Type": "application/json",
			"AUTHORIZATION": `Token ${token}`
			
          }
          const data = {
                lang: language,
          }
		  axios.post( `${process.env.REACT_APP_API_URL}/learning/api/language/`, data, {headers:headers} )
		  .then( response => {
			//   this.setState({sections:response.data});
		  } )
		  .catch(error => {
			  console.log(error);
			//   this.setState({error: true});
		  });

		  }
	  
		return (<div>
{/* 
<Navbar bg="light" expand="lg">
  <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="#home">Home</Nav.Link>
      <Nav.Link href="#link">Link</Nav.Link>
     
    </Nav>
   
  </Navbar.Collapse>
</Navbar> */}

		   <nav className="navbar navbar-expand-lg main-navbar">
				<div className="col-3">
					<Link to="/courses">
{localStorage.getItem('student_logo')!=undefined&&<img src={localStorage.getItem('student_logo')} className="imageSize" alt="Logo" />}  

                    </Link>
					</div>	
					<div className="col-md-8 col-7">

					</div>
				{/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<i className="fas fa-bars"></i>
				</button> */}
				{/* <Language /> */}
				{/* <div className="languageSelecter">
					<button onClick={() => handleClick('en')}>
						ENGLISH
					</button>
					<button onClick={() => handleClick('hi')}>
						HINIDI
					</button>
				</div> */}

				{/*<div className="collapse navbar-collapse" id="navbarSupportedContent">
				  <ul className="navbar-nav m-auto  nav-menu">
					 <li className="nav-item">
						<Link to="/dashboard">
					    <div className="nav-link" >{t('Home.1')}</div>
					  </Link>
					</li>
					<li className="nav-item">
						<Link to="/courses">
					  <div className="nav-link" >{t('Courses.1')}</div>
					  </Link>
					</li>
					<li className="nav-item">
						<Link to="/quizlist">
						<div className="nav-link">{t('Quiz.1')}</div>
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/assignment">
						<div className="nav-link" >{t('Assignments.1')}</div>
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/">
						<div className="nav-link" >{t('Logout.1')}</div>
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/dashboard">
						<div className="nav-link" >{t("About C4P.1")}</div>
						</Link> 
					</li>
				  </ul>
				  
						 */}
					
					<div className="dashboard-nav col-1">
		
					
					<div className="dropdown">
                      <button className="dropbtn fas fa-ellipsis-v i-tag nav-link " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        
                      </button> 
					  
					  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
					   <ul>
					   <Link to="/courses"><i className="fa fa-archive pl-2 pr-2"></i>Courses</Link><br></br>
					   <Link to="/assignment"><i className="fa fa-tasks pl-2 pr-2"></i>Assignment</Link><br></br>
					    <Link to="/profile"><i className="fas fa-user pl-2 pr-2"></i>Profile</Link><br></br>
						<Link to="/noti"><i className="fas fa-envelope pl-2 pr-2"></i>Notifications</Link><br></br>

						<p style={{
						    fontFamily: "Lato",
							fontSize: "14px",
							fontWeight: "normal",
							fontStyle: "normal",
							fontStretch: "normal",
							lineHeight: "2.21",
							letterSpacing: "normal",
							textAlign: "left",
							color: "#e74c3c",
							textTransform: "uppercase",
							cursor:"pointer"
						}} onClick={()=>{
							localStorage.clear();
							window.location.href = '/';
						}}><i className="fas fa-sign-out-alt pl-2 pr-2" ></i>{t("Logout.1")}</p><br></br>
					   </ul>
                      </div>
					   
                       </div> 

			 </div>
					
			  </nav>
			 
		</div>
	  )
		 
}
export default Navbar
