import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import {message} from "antd"
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));




const Question_Upload =(props)=> {
    const [data, setData] = useState('None');
    const [file, setFile] = useState("");

    const [id,setId]=useState(-1)
    const [name,setName]=useState("");


    const classes = useStyles();
    const [opens, setOpens] = React.useState(false);
  
  //   const handleClicks = () => {
  //     setOpens(true);
  //   };
  
    const handleCloses = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpens(false);
    };

  

    useEffect(()=>
    {
      const token = localStorage.Ltoken

    const headers = {
      "Content-Type": "application/json",
      "AUTHORIZATION": `Token ${token}`
      
      }
      axios.get( `${process.env.REACT_APP_API_URL}/learning/api/upload-pdf-answers/?test_series=${props.id}`, {headers:headers} )
      .then( response => {
                const extract_name= response.data.data[0].answersheet!='N/A'?response.data.data[0].answersheet.substring(response.data.data[0].answersheet.lastIndexOf("/")+1):"None"
                const extract_id= response.data.data[0].id
                setData(extract_name);
                setName(extract_name);
                setId(extract_id)

                 
      } )
      .catch(error => {
        console.log(error);
      });
    },[])

   

    const onFileChange = (event) =>
    {
      if(event.target.files[0]!=undefined && event.target.files[0].type!='application/pdf')
      {
      message.info("You can only upload pdf");
      }
      else if(event.target.files[0]!=undefined && event.target.files[0].type=='application/pdf'){
      setData(event.target.files[0].name);
      setFile(event.target.files[0]);
      }
      else{
        setData(name)
        setFile("");

      }
      
    }
const reset=(event)=>
{
  setData(name)
  setFile("");


}
    const submit = (event)=>
    {
      message.loading("Answers uploading..")
       const token = localStorage.Ltoken

        const headers = {
            "AUTHORIZATION": `Token ${token}`
            
          }
          if(typeof(file.name)!="undefined"){

          const formData = new FormData();
          formData.append("answersheet",file,file.name)
          formData.append("test_series",props.id)
          formData.append("id",id)

          axios.post( `${process.env.REACT_APP_API_URL}/learning/api/upload-pdf-answers/`,formData, {headers:headers} )
          .then( response => {
               if(response.statusText==='OK')
               {
                 message.destroy()
                 message.success("Answers uploaded successfully").then(()=>
                 message.info(`Please click on Final Submission to submit your answer`)
                 )
                // setOpens(true);
                setName(file.name);



               }
          } )
          .catch(error => {
              console.log(error);
              message.warning("Some error occured..Try again")
          });
        }
        else
        {
        message.destroy()  
        message.warning("Please upload your answer")
        }
        
    }
        return (
            <div>
            
              
                                            
                                <div className="input-group mb-5 mt-5">
                                <div className="input-group-prepend">
                                <span className="input-group-text" style={{cursor:'pointer'}} onClick={reset}>Cancel</span>

    <span className={`input-group-text mx-2 ${file!=''&& 'focused'}`}  style={{cursor:'pointer'}} onClick={submit}>Upload Answersheet</span>
  </div>
  <div className="custom-file">
    <input type="file" className="custom-file-input" 
      aria-describedby="inputGroupFileAddon01" onChange={onFileChange}/>
    <label className="custom-file-label">{data}</label>

  </div>
</div>                           
                        

<div className={classes.root}>
     {/* <Button variant="outlined" onClick={handleClicks}>
       Save
      </Button> */}
      <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
        <Alert onClose={handleCloses} severity="success">
          Answer Saved!
        </Alert>
      </Snackbar>
    
    </div>
    



</div>
                 
            
        )
   
}
export default Question_Upload