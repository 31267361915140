import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import './i18next';

import * as serviceWorker from './serviceWorker';
 if(document.location.pathname!='/')
  document.addEventListener('contextmenu', event => event.preventDefault());

  if(document.location.pathname!='/' && localStorage.getItem('Ltoken')===null){
    window.location.replace("/");
  }
//   document.addEventListener('contextmenu', event => event.preventDefault());
// el_down.innerHTML = "Right click disabled";

document.addEventListener("keyup", event => {
    console.log(event.keyCode, event.key, event.which)
    var keyCode = event.keyCode ? event.keyCode : event.which;
    if (keyCode == 44) {
    // stopPrntScr();
    }
});

// function stopPrntScr() {

//     var inpFld = document.createElement("input");
//     inpFld.setAttribute("value", ".");
//     inpFld.setAttribute("width", "0");
//     inpFld.stylevent.height = "0px";
//     inpFld.stylevent.width = "0px";
//     inpFld.stylevent.border = "0px";
//     document.body.appendChild(inpFld);
//     inpFld.select();
//     document.execCommand("copy");
//     inpFld.remove(inpFld);
// }

var flash = false;

setInterval(() => {
    if (flash = !flash) {
        document.getElementById('root').display = 'none';
    } else {
        document.getElementById('root').display = 'block';
    
    }
},1);

function AccessClipboardData() {
    try {
        window.clipboardData.setData('text', "Access   Restricted");
    } catch (err) {
    }
}
setInterval(AccessClipboardData(), 300);

(function () {
    (function a() {
        try {
            (function b(i) {
                if (('' + (i / i)).length !== 1 || i % 20 === 0) {
                    (function () { }).constructor('debugger')()
                } else {
                    debugger
                }
                b(++i)
            }
            )(0)
        } catch (e) {
            setTimeout(a, 5000)
        }
    }
    )()
}
)();

ReactDOM.render(
    <Suspense fallback={(<div>Loading</div>)}>
        <App />
    </Suspense>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
