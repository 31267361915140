import React,{useState,useEffect} from 'react';
import Navbar from '../Navbar';
import chapterFile from './chapterFile.png';
import axios from 'axios';
import SectionDetail from '../SectionDetail';
import Headline from '../heading';
import {Tab,Tabs} from 'react-bootstrap';
import {Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const After_Question =(props)=> {

 
    const [data, setData] = useState([]);
    const [useranswer,setUserAnswer] = useState("N/A")

    useEffect(()=>{
        const token = localStorage.Ltoken
           
			const headers = {
				"Content-Type": "application/json",
				"AUTHORIZATION": `Token ${token}`
				
        }
        // axios.get( `${process.env.REACT_APP_API_URL}/learning/api/upload-pdf-answers/?test_series=${props.id}`, {headers:headers} )
        // .then( response => {
        //         const x=response.data.data.map(d=>d.answersheet)
        //         setUserAnswer(x[0])
        //          console.log("MY RESPONSE",x[0])
  
                   
        // } )



			  axios.get( `${process.env.REACT_APP_API_URL}/learning/api/upload-answers/?test_series=${props.id}`, {headers:headers} )
			  .then( response => {
            //     const de=[]
            //     response.data.data.map(d=>
            //         de.push({"id":d.id,"answer": d.answer}))
              
            //    setData(de)
                  setData(response.data.data)
			  } )
			  .catch(error => {
				  console.log(error);
			  });
    },[])
  
   

      

        return (
            <div className="col">
              <ul className="list-group">
                <div className="row">

                <div className="col-md-3 ">
              {props.student_answer!='N/A'&&
               <a  rel="noopener noreferrer" href={`${props.student_answer}`} target="_blank" download className="download_button"><i class="fas fa-file-download fa-2x" style={{float:"left"}}></i>My Answersheet</a>
              }
               </div>
               {/* <div className="col-md-3 ">
              {props.answer!='N/A'&&
               <a  rel="noopener noreferrer" href={`${props.answer}`} target="_blank" download className="download_button"><i class="fas fa-file-download fa-2x" style={{float:"left"}}></i>Answer Key</a>
              }
               </div> */}
               
             </div>
              </ul>
            
             <div className="left-side col mt-5" style={{height:"100vh",overflow:"auto"}}>
            <ul className="list-group">        
             {data.map(d=>  <li className="list-group-item sec1">Q{d.question_no}.<textarea disabled={true} rows="8" style={{width:"100%"}}name={d.id} data-id={d.question_no} className="pr-3" value={d.answer} /></li>)}
             </ul>                    
            
      
                        </div>
                        </div>
        )
   
}
export default After_Question;