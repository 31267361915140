import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Navbar from '../Navbar';

const Notification=(props) => {
const [data,setData]=useState([]);
useEffect(()=>{
    const token = localStorage.Ltoken
    const headers = {
        "Content-Type": "application/json",
        "AUTHORIZATION": `Token ${token}`
        
      }
      axios.get( `${process.env.REACT_APP_API_URL}/license/api/student-get-notice/`, {headers:headers} )
      .then( response => {
            setData(response.data.data)
      } )
      .catch(error => {
          console.log(error);
      });


},[])
return(  

<>
<Navbar/>
<div className="container mt-5">
<h1 className="text-center my-5">Notifications</h1>
<div className="row">
   

{data.map((d,i)=> 
    <div className="col-md-12">
        {i%2==0?
        (<div className="alert alert-primary" role="alert">
        
            <div className="row">
                <div className="col-6 text-left">
                    {d.topic}
                </div> 
                <div className='col-6 text-right text-break'> 
                {d.date.substring(0,d.date.indexOf('T'))}
                </div>
            </div>
        
        <div className="mt-2 ">
            {d.notice}
        </div>
        
        </div>
        ): 
        (<div className="alert alert-success" role="alert">
        <div className="row">
            <div className="col-6 text-left">
                {d.topic}
            </div> 
            <div className='col-6 text-right text-break'> 
            {d.date.substring(0,d.date.indexOf('T'))}
            </div>
        </div>
    
    <div className="mt-2 ">
        {d.notice}
    </div>
    
    </div>)}

    </div>)}
</div>
</div>
</>    
)


    

}
export default Notification