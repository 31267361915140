import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {Tab,Tabs} from 'react-bootstrap';
import Course_List from "./Courses_List"
import {Link} from "react-router-dom";
import Navbar from '../Navbar';
import "./Chapters.css";
import Button from '@material-ui/core/Button';
import {Spin} from "antd"

const Courses =(props)=> {
   const [courses,setCourses] = useState([]);
   const [data,setData] = useState([]);
   const [load,setLoaded]=useState(false)
    useEffect(()=>
    {
      localStorage.removeItem("sid");

        const token = localStorage.Ltoken
			const headers = {
				"Content-Type": "application/json",
				"AUTHORIZATION": `Token ${token}`
				
			  }
			  axios.get( `${process.env.REACT_APP_API_URL}/learning/api/all-courses/`, {headers:headers} )
			  .then( response => {
				  setCourses(response.data.data)
            setLoaded(true)
			  } )
			  .catch(error => {
          console.log(error);
          setLoaded(true)

        });
        
        axios.get( `${process.env.REACT_APP_API_URL}/license/api/student-get-notice/`, {headers:headers} )
			  .then( response => {
				    setData(response.data.data)
			  } )
			  .catch(error => {
				  console.log(error);
			  });
    },[])
    // const data=courses.map(data=>data.subscription_plan.course.map(name=>name.course_name))

          return (
<>
                <Navbar />
                {/* <div className="container">
                <h1 className="text-center mt-5">Courses</h1>
                // {/* {data} */}
                {/* <div className="row">  */}
                {/* {courses.map(data=>data.subscription_plan.course.map(name=><div className="col-lg-4 mt-5">
                <div className="card">
                <Link to={`/courses/${name.id}`}>
                <div className="card-body">
                {name.course_name}
                </div>
                </Link>
                </div>
                </div> ))} */}
               <div className="text-center container-fluid mb-2 p-3 text-white" style={{background:"red"}}>
                {/* {data.length>0?(data[data.length-1].topic+": "+data[data.length-1].notice):("No notifications..")} */}
              {data.length>0?(<div>{`${data[data.length-1].topic}: ${data[data.length-1].notice}.... `}<Link to="/noti"><i style={{color:'white'}}class="fa fa-envelope" aria-hidden="true"></i></Link></div>):("..")}
                </div>
                <div className="container mb-5">
                 
                <h1 className="text-center mt-5 mb-5">Courses</h1>

                {!load&&<div className="text-center"><Spin/></div>}
              {/* {data.map(data=><>{data.topic}: {data.notice.substring(0,30)}</>)} */}
              {load&&
                <div className="row">
                {courses.map(data=>
                  <>
                  <Course_List name={data.course_name} id={data.id}/>
               
                </>

             
              
                )}
                   {courses.length<=0?(<div className='container text-center'>No courses available..</div>):(null)}
                </div>}
              </div>
                   
           
  </>
        )
   
}
export default Courses