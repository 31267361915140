import React from 'react';
import axios from 'axios';




class Language extends React.Component {
    state = {

        lang: 'English'
    }
    
    ponstdataHandler = () =>{
    this.props.handleLang(this.state.lang=='English'?'en':'hi');  
		const token = localStorage.Ltoken
		const headers = {
			"Content-Type": "application/json",
			"AUTHORIZATION": `Token ${token}`
			
          }
          const data = {
                lang: this.state.lang,
          }
		  axios.post( `${process.env.REACT_APP_API_URL}/learning/api/language/`, data, {headers:headers} )
		  .then( response => {
			//   this.setState({sections:response.data});
			  console.log( response.data );
		  } )
		  .catch(error => {
			  console.log(error);
			//   this.setState({error: true});
		  });
    }
    render() {
        return(
                <div class="dropdown">
                  <select className="form-control" id="exampleFormControlSelect1" onChange={(event) => this.setState({lang: event.target.value})} >
								<option selected>Select Language</option>
							    <option value='Hindi' >Hindi</option>
								<option value='English'>English</option>
				</select>
                <button onClick={this.ponstdataHandler}>Change</button>
                </div>
        )
    }

}

export default Language;