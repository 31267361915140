import React,{useState} from 'react';
import chapterFile from './chapterFile.png';
import { Link } from 'react-router-dom';


const Assignment_Card=(props) => {
   
const  {name,id,ques} = props;
    if(props.is_checked)
    return (   
        <React.Fragment>
		
        <div className="col-md-2 mt-3">
        
        <Link to={`assignments/${id}/answers`} >
        <div className="card">
        <div className="card-body"><p className="blockquote">{name}</p>
        <p style={{fontSize:"12px"}} >View Result</p>
        </div>
  </div>
  </Link>
</div>       
        </React.Fragment>
    )

    else if(!props.is_finished)
    return (   
        <React.Fragment>
		
        <div className="col-md-2 mt-3">
        
        <Link to={`assignments/${id}/questions`} >
        <div className="card">
        <div className="card-body"><p className="blockquote">{name}</p>
        <p style={{fontSize:"12px"}} >Total Questions: {ques}</p>
        </div>
  </div>
  </Link>
</div>       
        </React.Fragment>
    )

   

    else if(props.is_finished && !props.is_checked )
    return (   
        <React.Fragment>
		
        <div className="col-md-2 mt-3">
        
        <Link to={`assignments/${id}/submission`} >
        <div className="card">
        <div className="card-body"><p className="blockquote">{name}</p>
        <p style={{fontSize:"12px"}} >View Submission</p>

        </div>
  </div>
  </Link>
  
</div>       
        </React.Fragment>
    )


    

}
export default Assignment_Card