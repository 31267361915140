import React, { Component } from 'react';
import axios from 'axios';


class SectionDetail extends Component {
    state = {
        loadeddetail: null
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.Ltoken
        const headers = {
            "Content-Type": "application/json",
            "AUTHORIZATION": `Token ${token}`

        }
        if (this.props.id && (prevProps.id != this.props.id)) {
            if (!this.state.loadeddetail || (this.state.loadeddetail && this.state.loadeddetail.id !== this.props.id)) {
                axios.get(`${process.env.REACT_APP_API_URL}/learning/api/learning/chapter/section/` + this.props.id, { headers: headers })
                    .then(response => {
                        // console.log(response.data);
                        this.setState({ loadeddetail: response.data });
                    });
            }
        }
    }


    render() {
        let detail = <h3 style={{ textAlign: 'center' }}>Please select a Section!</h3>;
        if (this.props.id) {
            detail = <h3 style={{ textAlign: 'center' }}>Loading...!</h3>;
        }
        if (this.state.loadeddetail) {
            detail = (
                <>
                    <h3>{this.state.loadeddetail}</h3>
                </>
            );
        }
        return detail;
    }
}

export default SectionDetail;