import React,{useState,useEffect} from 'react';
import Navbar from '../Navbar';
import chapterFile from './chapterFile.png';
import axios from 'axios';
// import SectionDetail from './SectionDetail';
import {Tab,Tabs} from 'react-bootstrap';
// import Navi from './Test_Paper'
import {Link} from "react-router-dom";
const Total_Score =(props)=> {
    const [content, setContent] = useState([]);
    const [loaded,setLoading]=useState(false);
    const [title,setTitle]=useState(null)
    useEffect(()=>{
        const token = localStorage.Ltoken
       
			const headers = {
				"Content-Type": "application/json",
				"AUTHORIZATION": `Token ${token}`
				
              }
              axios.get( `${process.env.REACT_APP_API_URL}/learning/api/test-series-view/?test_series=${props.match.params.id}`, {headers:headers} )
			  .then( response => {
                setTitle({title:response.data.data.name})

			  } )

			  axios.get( `${process.env.REACT_APP_API_URL}/learning/api/test-series-students/?test_series=${props.match.params.id}`, {headers:headers} )
			  .then( response => {
                setContent(response.data.data)
                if (response.data.data.length>0)
                  setLoading(true);
			  } )
			  .catch(error => {
				  console.log(error);
			  });
    },[])

    


if(loaded){
        return (
            <div>
                <Navbar />
                <main className="container-fluid mt-5">
                <h1 className="text-center">Assignment - {title.title}</h1>
                <ul className="list-group list-group-horizontal ">
                {/* <Link to={`/assignments/${props.match.params.id}/questions`}><li className="list-group-item" >Question</li></Link> */}
                <Link to={`/assignment`}><li  key="2" className="list-group-item"><i class="fas fa-angle-left mr-2"></i>  Back</li></Link>
               
               <Link to={`/assignments/${props.match.params.id}/paper`} ><li className="list-group-item">Question Paper</li></Link>
              
               <Link to={`/assignments/${props.match.params.id}/submission`} ><li className="list-group-item ">My Submission</li></Link>
               <Link to={`/assignments/${props.match.params.id}/answers`} ><li className="list-group-item ">Checked Sheet</li></Link>
               <Link to={`/assignments/${props.match.params.id}/topper`} ><li className="list-group-item ">Topper Copy</li></Link>
               <Link to={`/assignments/${props.match.params.id}/marks`} disabled={true}><li className="list-group-item active">Score Board</li></Link>

</ul>
</main>
                    <h1 className="text-center my-5" style={{fontSize:"22px"}}>Score Board </h1>

                     
                   <div className="container text-center mt-5" style={{height:"80vh",overflow:'auto'}}>
                    <table className="table">
                        <thead>
                    <tr>
                        <th>Student Name</th>
                        <th>Total Marks</th>

                    </tr>
                    </thead>
                    <tbody>
        {content.map((data,key)=><tr key={key}>
            <td>{data.user.first_name}</td>
            <td>{data.user.total_marks>0?(data.user.total_marks):("-")}</td>
            </tr>)}
            </tbody>
                    </table>                    
                   
                                </div>       
                    

              
            </div>
        )
}
else{
    return(
    <div>
    <Navbar />
    <main className="container-fluid mt-5">
    <h1 className="text-center">Assignment - {title!=null&&title.title}</h1>
    <ul className="list-group list-group-horizontal ">
    <Link to={`/assignment`}><li  key="2" className="list-group-item"><i class="fas fa-angle-left mr-2"></i>  Back</li></Link>
               
               <Link to={`/assignments/${props.match.params.id}/paper`} ><li className="list-group-item">Question Paper</li></Link>
              
               <Link to={`/assignments/${props.match.params.id}/submission`} ><li className="list-group-item ">My Submission</li></Link>
               <Link to={`/assignments/${props.match.params.id}/answers`} ><li className="list-group-item ">Checked Sheet</li></Link>
               <Link to={`/assignments/${props.match.params.id}/topper`} ><li className="list-group-item ">Topper Copy</li></Link>
               <Link to={`/assignments/${props.match.params.id}/marks`} disabled={true}><li className="list-group-item active">Score Board</li></Link>

</ul>
</main>
<div className="container mt-5 text-center">
   <h1 className="text-center">Loading...</h1>
</div>
</div>
    )
}
   
}
export default Total_Score;